import type { ErrorEvent, EventHint } from '@sentry/types';
import type { MentiError } from '@mentimeter/errors';

export function errorMiddleware(
  middleware: (
    event: ErrorEvent,
    hint: EventHint,
    originalException: Error,
  ) => ErrorEvent | null,
) {
  return (event: ErrorEvent, hint: EventHint): ErrorEvent | null => {
    if (event.environment === 'dev') {
      // eslint-disable-next-line no-console
      console.log('Sentry capture exception received:', event, hint);
    }

    if (!hint.originalException) {
      // Without an original exception, there's very little to go on
      // Don't send to Sentry
      return null;
    }

    const originalException =
      typeof hint.originalException === 'string'
        ? ({ message: hint.originalException } as MentiError)
        : (hint.originalException as MentiError);

    if (originalException.feature) {
      event.tags = {
        ...event.tags,
        feature: originalException.feature,
      };
    }

    return middleware(event, hint, originalException);
  };
}
